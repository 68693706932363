<template>
  <b-card>
    <FormBuilder
      label="User"
      update
      :data="$store.state.user.detailData"
      :fields="fields"
      :loading-detail="$store.state.user.loadingDetail"
      :loading-submit="$store.state.user.loadingSubmit"
      @load="loadData"
      @submit="submitData"
      @reset="resetData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'avatar',
          label: 'Profile picture',
          type: 'image',
          notes: 'It will show up on their user profile page as user avatar image',
        },
        {
          key: 'nickname',
          label: 'Username',
          required: true,
          notes: [
            'It will be used as a URL to their profile page in the format ganknow.com/<i>username</i>.',
            '<b>Be careful,</b> their profile page will not be accessible under the old URL.',
          ],
        },
        {
          key: 'email',
          type: 'email',
          label: 'Email',
        },
        {
          key: 'firstName',
          label: 'First name',
        },
        {
          key: 'lastName',
          label: 'Last name',
        },
        {
          type: 'section',
          title: 'Additional information',
        },
        {
          key: 'description',
          label: 'Description',
          type: 'textarea',
          minLength: 5,
          notes: 'It will show up on their user profile page under "Get to know me!" section.',
        },
        {
          key: 'tags',
          label: 'Tags',
          type: 'multiple',
          options: [
            {
              value: 'NSFW',
              text: 'NSFW',
            },
          ],
        },
        {
          key: 'active',
          label: 'Active',
          type: 'switch',
          text: 'Active',
          notes: 'Deactivating the user will make them unable to access the Gank application and have them sign out of all sessions.',
        },
        {
          key: 'verified',
          label: 'Email verified',
          type: 'switch',
          text: 'Verified',
          notes: 'Verified user can enable donations, enable membership, create a listing, etc.',
        },
        {
          key: 'score',
          label: 'Score',
          type: 'slider',
          min: -100,
          max: 100,
          marks: [-100, -50, 0, 50, 100],
        },
        {
          type: 'section',
          title: 'Social media',
          description: 'It will show up on their user profile page under "Follow me on other platforms!" section.',
        },
        {
          key: 'profile.instagramUrl',
          label: 'Instagram URL',
          type: 'url',
        },
        {
          key: 'profile.tiktokUrl',
          label: 'Tiktok URL',
          type: 'url',
        },
        {
          key: 'profile.facebookUrl',
          label: 'Facebook URL',
          type: 'url',
        },
        {
          key: 'profile.twitterUrl',
          label: 'Twitter URL',
          type: 'url',
        },
        {
          key: 'profile.youtubeUrl',
          label: 'Youtube URL',
          type: 'url',
        },
        {
          key: 'profile.twitchUrl',
          label: 'Twitch URL',
          type: 'url',
        },
        {
          key: 'profile.streamUrl',
          label: 'Stream URL',
          type: 'url',
        },
        {
          key: 'profile.discordUrl',
          label: 'Discord URL',
          type: 'url',
        },
      ],
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('user/getDetail', id)
    },
    submitData(id, data, callback) {
      this.$store.dispatch('user/update', { id, data })
        .then(callback)
    },
    resetData() {
      return this.$store.commit('user/SET_DETAIL_DATA', undefined)
    },
  },
}
</script>

<style scoped>

</style>
